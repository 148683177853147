export const LANGUAGES = {
  "en-US": "English (US)",
  "es-ES": "Spanish (Spain)",
  "pt-BR": "Brazilian Portuguese",
  // "fr-FR": "French (France)",
  // "de-DE": "German (Germany)",
  // "ja-JP": "Japanese (Japan)",
  // // "zh-CN": "Chinese Simplified (China)",
  // "zh-TW": "Chinese Traditional (Taiwan)",
  // "it-IT": "Italian (Italy)",
};
