import "../../assets/styles/styles.scss";
import classes from "classnames";

const Chip = ({
  text,
  icon,
  id,
  className = "",
  onClick = null,
  size = "lg",
  onDismiss = null,
  style = {},
}) => {
  const SIZES = {
    sm: { svg: "18px" },
    md: { svg: "25px" },
    lg: { svg: "33" },
  };
  return (
    <span
      className={`${classes("chip", className)} ${
        onClick ? "touchable-opacity" : ""
      }`}
      style={style}
      onClick={() => {
        typeof onClick === "function" && onClick(id);
      }}
    >
      {icon} {text}{" "}
      {typeof onDismiss === "function" && (
        <button
          style={{ marginRight: -4 }}
          type="button"
          onClick={() => {
            typeof onDismiss === "function" && onDismiss(id, text);
          }}
        >
          <svg
            height={SIZES[size].svg}
            width={SIZES[size].svg}
            stroke="currentColor"
            fill="#808080"
            strokeWidth="0"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
          </svg>
        </button>
      )}
    </span>
  );
};

export default Chip;
